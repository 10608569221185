import { ErrorObject } from "@lu/validator";

export const getErrorText = (errors: ErrorObject | undefined, name: string): string | undefined => {
	if (!errors) return undefined;
	if (!(name in errors)) return undefined;
	const err = errors[name];
	if (typeof err === "object") {
		return Object.entries(err)
			.map(([, message]) => message.toString())
			.join("\n");
	}
	return err;
};
export const getErrorObject = (errors: ErrorObject | undefined, name: string): ErrorObject | undefined => {
	if (!errors) return undefined;
	if (!(name in errors)) return undefined;
	const err = errors[name];
	if (typeof err === "string") return undefined;
	return err;
};
