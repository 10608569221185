import * as React from "react";
import { FormControl } from "react-bootstrap";
import { InputBaseProps } from "..";
import classnames from "classnames";
import { VisibleIcon, InvisibleIcon } from "../../icons";

export type PasswordProps = {
	onChange: (value: string) => void;
	value: string;
	placeholder?: string;
} & InputBaseProps;

export const Password = React.memo((props: PasswordProps) => {
	const { onChange, value, ...textProps } = props;
	const onChangeDate = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value), [
		onChange,
	]);
	const [view, setView] = React.useState(false);
	const onChangeView = React.useCallback(() => setView(!view), [view]);
	return (
		<div className={classnames("password-area")}>
			<FormControl value={value || ""} onChange={onChangeDate} type={view ? "text" : "password"} {...textProps} />
			<div className={classnames("view-icon")} onClick={onChangeView}>
				{view ? <InvisibleIcon /> : <VisibleIcon />}
			</div>
		</div>
	);
});
