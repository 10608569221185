import * as React from "react";
import { FormControl } from "react-bootstrap";
import { InputBaseProps } from "..";

export type TextareaProps = {
	onChange: (value: string) => void;
	value: string;
} & InputBaseProps;

export const Textarea = React.memo((props: TextareaProps) => {
	const { onChange, value, ...textareaProps } = props;
	const onChangeDate = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value), [
		onChange,
	]);
	return <FormControl as={"textarea"} value={value || ""} onChange={onChangeDate} {...textareaProps} />;
});
